import { get, remove } from '@/service/api';
import remapSortDesc, { buildSortUrl, buildUrl } from '@/utils/service-utils';

export default async function getAllUsersPageable(page, size, orderBy, sortBy, filter) {
  const resp = await get(`ui-integrator/user/fetch?page=${page}&size=${size}&order_by=${orderBy}&sort_by=${sortBy}&filter=${filter}`);
  return resp;
}

export async function listUsers(formData = {}, paginationOptions) {
  const { page, itemsPerPage, sortDesc, sortBy } = paginationOptions;
  const params = await buildUrl(page, itemsPerPage);
  const mapedOrder = remapSortDesc(sortDesc);
  const sortOptions = buildSortUrl(sortBy, mapedOrder);
  const sortOptionsFormat = sortOptions.replace('groups', 'userGroups.name');

  const { name } = formData;
  const query = [name ? `( name = !${name} )` : ''].filter((condition) => condition !== '').join(' and ');

  const data = await get(`ui-integrator/user/fetch?${params}&filter=${query}${sortOptionsFormat}`);
  return data;
}

export async function removeUser(id) {
  return remove(`ui-integrator/user/${id}`, {}, { headers: {} });
}

export async function getSellers(currentUser) {
  const { data } = await get(`ui-integrator/sellers?sellers=${currentUser.sellers.toString()}`, {
    headers: {
      sellerId: currentUser.sellerId,
    },
  });

  return data?.register;
}
