<template>
  <v-dialog v-model="showModal" :max-width="maxWidth" persistent>
    <template>
      <v-card>
        <v-card-text>
          <div class="d-flex flex-column pt-5">
            <div class="align-self-center">
              <v-img src="../../assets/images/WarningImg.svg" class="alert-warning-img" />
            </div>
            <div class="custom-alert-title">
              <slot name="header"></slot>
            </div>
            <div class="custom-alert">
              <slot name="content"></slot>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="d-flex flex-column">
            <div class="custom-alert mb-10">
              <slot name="footer"></slot>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxWidth: {
      type: String || Number || undefined,
      default: '500',
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-alert-title {
  font-size: 20px;
  color: #23272b;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-alert {
  font-size: 16px;
  color: #7a7e83;
  margin: 10px;
  text-align: center;
  align-self: center;
  width: 450px;
}

.alert-warning-img {
  margin-top: 20px;
  width: 150px;
  height: 150px;
}
</style>
