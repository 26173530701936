export function mapperToSellerRequest(formData) {
  const seller = {
    activity: formData.activity,
    marketplaceIdPrefix: formData.marketplaceIdPrefix,
    marketplaceSlug: formData.marketplaceSlug,
    bankAccount: {
      agency: formData.bankAccount.agency,
      number: formData.bankAccount.number,
    },
    cnpj: formData.cnpj,
    companyName: formData.companyName,
    contacts: formData.contacts,
    site: formData.site,
    soldAndDeliveredBy: formData.soldAndDeliveredBy,
    tradingName: formData.tradingName,
    zipCode: formData.zipCode,
    productAutoApprove: formData.productAutoApprove,
    allowsCommissionCalculation: formData.allowsCommissionCalculation,
  };
  if (formData.id) seller.id = formData.id;
  if (formData.bankAccount.bankCode.value) {
    seller.bankAccount.bankCode = formData.bankAccount.bankCode.value;
  } else seller.bankAccount.bankCode = formData.bankAccount.bankCode;
  if (formData.integrationPlatform.value) {
    seller.integrationPlatform = formData.integrationPlatform.value;
  } else seller.integrationPlatform = formData.integrationPlatform;
  if (formData.originData.value) {
    seller.originData = formData.originData.value;
  } else seller.originData = formData.originData;
  return seller;
}

export default mapperToSellerRequest;
