import { get, post, put } from '@/service/api';
import { mapperToSellerRequest } from './seller-mapper';
import remapSortDesc, { buildSortUrl, buildUrl } from '@/utils/service-utils';

export default async function getAllListSellers(page, itemsPerPage, query, sortBy, orderBy) {
  const params = await buildUrl(page, itemsPerPage);
  const mapedOrder = remapSortDesc(orderBy);
  const sortOptions = buildSortUrl(sortBy, mapedOrder);
  const data = await get(`ui-integrator/sellers/fetch?${params}&filter=${query}${sortOptions}`);
  return data;
}

export async function getAllUserSellers(username) {
  const resp = await get(`ui-integrator/sellers/fetch-user-sellers?username=${username}`);
  return resp.data;
}

export async function createSeller(form) {
  const response = await post('ui-integrator/sellers/create', mapperToSellerRequest(form));
  return response.data ? response : { ...response.response };
}

export async function getSeller(id) {
  const resp = await get(`ui-integrator/sellers/${id}`);
  return resp.data;
}

export async function updateSeller(form) {
  const response = await put(`ui-integrator/sellers/${form.id}`, mapperToSellerRequest(form));
  return response.data ? response : { ...response.response };
}

export async function getAllSellersByIds(ids) {
  const resp = await get(`ui-integrator/sellers/fetch-by-ids?sellers=${ids}`);
  return resp.data;
}
