<template>
  <v-container class="crud-content">
    <ListLayout :title="title" :info="info" :buttonActions="buttonActions">
      <template v-slot:filter>
        <filter-container>
          <template v-slot:content>
            <user-form-filter @onSubmit="onSubmitFilterHandler" @onReset="onResetFilterHandler" />
          </template>
        </filter-container>
      </template>
      <template v-slot:content>
        <v-data-table
          :headers="header"
          :loading="loading"
          :items="content"
          class="elevation-1 custom-table"
          :options.sync="paginationOptions"
          :server-items-length="totalElements"
          :footer-props="{
            'items-length': 100,
            'items-per-page-text': 'Linhas por página',
            'items-per-page-options': [10, 20, 50],
            pageText: '{0}-{1} de {2}',
          }"
        >
          <template v-slot:[`item.groups`]="{ item }">
            <v-chip class="itens">{{ getGroupNames(item.groups) || 'Nenhum grupo' }}</v-chip>
          </template>
          <template v-slot:[`item.sellers`]="{ item }">
            <v-chip class="itens">{{ getSellersNames(item.sellers) || 'Nenhum seller' }}</v-chip>
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <v-icon v-if="item.enabled" color="var(--green-0)">mdi-check</v-icon>
            <v-icon v-else color="var(--red-0)">mdi-close</v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="primary" @click="openDetail(item.id)">mdi-pencil</v-icon>
            <v-icon color="primary" @click="deleteItem(item)" class="mr-3">mdi-delete</v-icon>
          </template>
          <template v-slot:no-data>
            <div class="pt-10 pb-10">
              Nenhum resultado encontrado
            </div>
          </template>
        </v-data-table>
      </template>
    </ListLayout>
    <AlertModal :showModal="showModal">
      <template v-slot:header>
        <div class="d-flex flex-column">
          Atenção!
        </div>
      </template>
      <template v-slot:content>
        <div class="d-flex flex-column">
          <div class="mb-3">
            Todas as informações deste usuário serão perdidas com esta ação
          </div>
          <div>Deseja mesmo excluir este usuário?</div>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn outlined color="primary" class="mr-3" @click="closeModal()">CANCELAR EXCLUSÃO</v-btn>
        <v-btn color="primary" @click="removeUser()">SIM, QUERO EXCLUIR</v-btn>
      </template>
    </AlertModal>
  </v-container>
</template>

<script>
import ListLayout from '@/components/template/list/ListLayout';
import FilterContainer from '@/components/filter-layout/FilterContainer';
import AlertModal from '@/components/modal/AlertModal';
import dataCrudMixin from '../../components/template/crud/mixins/dataCrudMixin';
import { listUsers, removeUser } from '../../service/user/user-service';
import { getAllSellersByIds } from '../../service/seller/seller-service';
import notification from '@/service/notification';
import { routerPush } from '@/service/kplaceRouter';
import UserFormFilter from './filter/UserFormFilter';

export default {
  components: {
    ListLayout,
    FilterContainer,
    AlertModal,
    UserFormFilter,
  },
  mixins: [dataCrudMixin],
  data() {
    return {
      userClickedItem: {},
      showModal: false,
      title: 'Usuários',
      paginationOptions: {},
      formDataFilter: {},
      buttonActions: [{ name: 'CADASTRAR USUÁRIO', action: this.newUser }],
      info: '',
      loading: false,
      header: [
        { text: 'Nome', value: 'name', sortable: true },
        { text: 'Perfis', value: 'groups', sortable: true },
        { text: 'Sellers', value: 'sellers', sortable: false },
        { text: 'Status', align: 'center', value: 'enabled', sortable: true },
        { text: 'Ação', sortable: false, value: 'actions', align: 'center' },
      ],
      content: [],
    };
  },
  watch: {
    paginationOptions: {
      handler() {
        this.init();
      },
    },
  },
  methods: {
    init() {
      this.getUserListHandler(this.formDataFilter);
    },
    async getUserListHandler(formDataFilter) {
      try {
        this.loading = true;
        const requestReponse = await listUsers(formDataFilter, this.paginationOptions);
        if (requestReponse.status >= 200 && requestReponse.status <= 300) {
          this.content = requestReponse.data.register;
          this.totalElements = requestReponse.data.totalRegister;
          await this.fetchSellersName(this.content);
        } else {
          notification(`${requestReponse.data.message}`, 'error');
        }
      } catch (e) {
        notification(`Erro ao realizar busca de usuarios : ${e}`, 'error');
      } finally {
        this.loading = false;
      }
    },
    async fetchSellersName(data) {
      data.map(async (item) => {
        const resp = await getAllSellersByIds(item.sellers);
        item.sellers = resp;
      });
    },
    showAlertModal(item) {
      this.userClickedItem = item;
      this.showModal = !this.showModal;
    },
    closeModal() {
      this.showModal = false;
    },
    async removeUser() {
      try {
        const item = this.userClickedItem;
        const response = await removeUser(item.id);
        if (response.status === 204) {
          notification('Usuário removido com sucesso.', 'success');
          this.content = this.content.filter((e) => e.id !== item.id);
        } else {
          notification('Erro ao remover usuário.', 'error');
        }
      } catch (error) {
        notification(`Erro ao remover o usuário: ${error.response?.data.message}`, 'error');
      }
      this.userClickedItem = {};
      this.showModal = false;
    },
    getGroupNames(groups) {
      return groups.map((group) => group.name).join(', ');
    },
    getSellersNames(sellers) {
      return sellers.map((seller) => seller.companyName).join(', ');
    },
    newUser() {
      routerPush('/user/forms');
    },
    openDetail(id) {
      routerPush(`/user/forms/edit/${id}`);
    },
    deleteItem(item) {
      this.showAlertModal(item);
    },
    onSubmitFilterHandler(formDataFilter) {
      this.formDataFilter = formDataFilter;
      this.getUserListHandler(this.formDataFilter);
      this.paginationOptions.page = 1;
    },
    onResetFilterHandler(formDataFilter) {
      this.formDataFilter = formDataFilter;
      this.getUserListHandler(this.formDataFilter);
      this.paginationOptions.page = 1;
    },
  },
  async mounted() {
    if (this.paginationOptions.sortBy.length === 0) {
      this.paginationOptions.sortBy.push('name');
      this.paginationOptions.sortDesc.push(true);
    }
    await this.init();
  },
};
</script>

<style>
.crud-content {
  background-color: #f8f8f8;
  height: calc(100% - 160px);
  width: 100%;
  margin-top: 126px;
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
}
</style>
