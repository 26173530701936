<template>
  <v-form ref="filterForm" lazy-validation @submit="onSubmitHandler">
    <content-base-layout>
      <template v-slot:content>
        <div class="form-content">
          <div class="col-4">
            <label class="filter-label">Nome do usuário</label>
            <v-text-field v-model="form.name" placeholder="Pesquise por usuários..." dense filled prepend-inner-icon="mdi-magnify" />
          </div>
        </div>
      </template>

      <template v-slot:actions>
        <filter-base-actions @onReset="onClearHandler" />
      </template>
    </content-base-layout>
  </v-form>
</template>

<script>
import ContentBaseLayout from '@/components/filter-layout/ContentBaseLayout';
import FilterBaseActions from '@/components/filter-layout/FilterBaseActions';

const FilterFields = {
  name: '',
};

export default {
  components: { ContentBaseLayout, FilterBaseActions },
  data() {
    return {
      form: { ...FilterFields },
    };
  },
  methods: {
    onSubmitHandler(e) {
      e.preventDefault();
      const { filterForm } = this.$refs;
      const validated = filterForm?.validate();

      if (!validated) return;

      const formData = { ...this.form };
      this.$emit('onSubmit', formData);
    },
    onClearHandler() {
      this.form = { ...this.form, ...FilterFields };
      this.$emit('onReset', this.form);
    },
  },
};
</script>
